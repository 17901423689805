<template>
  <overlay-scrollbars class="page page-payment_atlas" :class="{ 'soon': comingSoon }" ref="os">
    <div class="row row_top">
      <div class="box box_paymentAtlas">
        <div class="col col_img"><img src="/img/pa.png"></div>
        <div class="col col_content">
          <div class="ttl">PAYMENT NET</div>
          <p class="p1">
            {{ $store.getters.contentArr.payment_atlas_text }}
          </p>
          <p class="p1">
            {{ $store.getters.contentArr.payment_atlas_text_1 }}
          </p>
          <div class="footer">
            <div class="btn">{{ $store.getters.contentArr.payment_atlas_button }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box_legalService">
      <div class="content">
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank Accounts</div>
          </div>
          <Table
            :options="paymentAtlases" :key="paymentAtlasesKey"
            @apply="apply" @change-page="getPaymentAtlases" @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table.vue'

import Arrow from '@/components/img/Arrow.vue'
import Dots from '@/components/img/Dots.vue'
import Bank2 from '@/components/img/Bank2.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Table,
    Arrow,
    Dots,
    Bank2,
    Pagination,
  },
  data: () => ({
    comingSoon: true,
    paymentAtlasesData: {},
    paymentAtlasesKey: 0,
    paymentAtlases: {
      colsWidth: ['4%', '14%', '14%', '12%', '32%', '12%', '12%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country of Bank', info: null },
        { ttl: 'Type of Account', info: null },
        { ttl: 'Transaction History', info: null },
        { ttl: 'Balance', info: null },
        { ttl: 'Make a Payment', info: null, class: 'gradient_btn' },
      ],
      body: [],
      paginationData: null
    }
  }),
  created() {
    this.getPaymentAtlases()
  },
  methods: {
    getFinName (el) {
      return !el.provider_id && !el.is_legal_company
        ? el.bank_name
        : {
            type: 'info',
            html: el.bank_name,
            info: {
              pos: 'tr',
              txt: 'Provided by Legal company'
            },
          }
    },
    getPaymentAtlases(page = 1) {
      const that = this
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'payment-atlas?page=' + page)
        .then(function (response) {
          that.paymentAtlases.comingSoon = that.comingSoon
          that.paymentAtlases.paginationData = that.paymentAtlasesData = response.data
          that.paymentAtlases.body = []
          that.paymentAtlasesData.data.forEach(el => {
            const applyText = (el.applied) ? 'Applied' : 'Apply'
            const applyBtn = { type: 'apply-btn', html: applyText, applyType: 'payment-atlas', id: el.id }
            that.paymentAtlases.body.push([
                el.serial_number, that.getFinName(el), el.country_code, el.account_type,
                el.transaction_history, that.number_format(el.balance, 2, '.', ' ') + '€', applyBtn
              ])
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply (payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'payment-atlas/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          const data = that.paymentAtlasesData.data
          for (var i = 0; i < data.length; i++) {
            const el = data[i]
            if (el.id === payload.id) {
                if (res.changed) {
                  that.$noty.success("Apply status was successfully changed")
                  that.paymentAtlases.body[i] = [
                    el.serial_number, el.bank_name, el.country_code, el.account_type,
                    el.transaction_history, that.number_format(el.balance, 2, '.', ' ') + '€', { type: 'apply-btn', html: 'Applied', applyType: 'payment-atlas', id: el.id }
                  ]
                  that.paymentAtlasesKey++
                }
            }
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-payment_atlas {
  &.soon {
    .box_legalService {
      ::v-deep .tbl {
        &_body {
          filter: blur(5px);

          .tbl_row {
            &:hover {
              background-color: inherit;
            }

            .btn {
              cursor: default;
            }
          }
        }

        &_footer {
          filter: blur(5px);

          .pagination {
            .page-link {
              cursor: default;
            }
          }
        }
      }
    }
  }

  .row {
    &_top {
      display: flex;
      height: 16rem;
      margin-top: 1.5rem;
    }
  }

  .box {
    &_paymentAtlas {
      width: 100%;
      height: 100%;
      margin: 1rem 0;
      background-color: #ffffff;
      display: flex;

      .col {
        &_img {
          width: 40%;
          display: flex;
          justify-content: center;

          img {
            height: 120%;
            position: relative;
            top: 100%;
            transform: translateY(-95%);
          }
        }

        &_content {
          width: 60%;
          padding: 1rem;
          display: flex;
          flex-direction: column;

          .ttl {
            color: #62CDAC;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            position: relative;
            padding-left: 3rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 2.25rem;
              height: 1px;
              background: #62CDAC;
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 14px;
            }

          }

          p {
            color: #22252B;

            &.p3 {
              padding: .25rem .75rem .25rem 0;
            }
          }

          .footer {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-grow: 1;

            .btn {
              font-size: 18px;
              padding: 1rem 3rem;
              margin: .25rem 1rem;
              background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }

    &_freeCons {
      width: calc(25% - 2rem);
      height: 100%;
      margin: 1rem 0 1rem 1rem;
      padding: 1.25rem 1.75rem;
      background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      .dots {
        position: absolute;

        &-top {
          top: 2px;
          left: 1.75rem;
          width: 2rem;
        }

        &-bottom {
          bottom: -12px;
          right: 2rem;
          width: 2.75rem;
          transform: rotate(90deg);
          opacity: 0.5;
        }
      }

      .content {
        font-size: 1.5rem;
        padding: 1.5rem .75rem 1.5rem 0;
      }

      .btn {
        padding: 0.75rem 1.25rem;
        background: #272E35;
        border-radius: 6px;
        color: #62CDAC;
        display: flex;
        align-items: center;

        ::v-deep svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: 1rem;

          path {
            fill: #ffffff;
          }
        }
      }
    }

    &_legalService {
      margin-top: 3rem;
      background-color: #000000;

      .content {
        .block {
          margin: 1rem 0;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: 1rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}

.pagination {
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}
</style>